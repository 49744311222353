<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Clientes'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right
                                    rounded 
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                         <template slot="filters">
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Correo"
                                    v-model="correo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Teléfono"
                                    v-model="telefono"
                                    auto
                                    v-mask="'(###) ###-####'"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="estados"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Estado"
                                    persistent-hint
                                    v-model="estado_value"
                                    no-data-text="Datos no disponibles"
                                ></v-autocomplete>
                            </v-col>
                         </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div>{{ item.nombre }}</div>
                                    <div class="tblSecundario">{{ item.correo }}</div>
                                </td>
                                <td>
                                    <div>{{ item.telefono }}</div>
                                </td>
                                <td>
                                    <div v-if="item.nominas">Nóminas</div>
                                    <div v-else>GDTH</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
            <template class="borde-card">
                <v-dialog v-model="dialog" scrollable max-width="700px" persistent class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar>
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="6" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre"
                                                        v-model="cliente.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Correo" rules="required|email">
                                                    <v-text-field
                                                        outlined
                                                        label="Correo"
                                                        v-model="cliente.correo"
                                                        required
                                                        :error-messages="errors"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Teléfono" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Teléfono"
                                                        v-model="cliente.telefono"
                                                        :error-messages="errors"
                                                        auto
                                                        v-mask="'(###) ###-####'"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="6" md="4" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Código postal"
                                                        v-model="busca_cp"
                                                        :error-messages="errors"
                                                        @keyup.enter="busca_codigos()"
                                                        v-on:keydown.tab="busca_codigos()"
                                                        v-mask="'#####'"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="6" md="4" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Estado"
                                                    v-model="estado"
                                                    disabled
                                                />
                                            </v-col>
                                            <v-col xs="12" sm="6" md="4" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Municipio"
                                                    v-model="municipio"
                                                    disabled
                                                />
                                            </v-col>
                                            <v-col xs="12" sm="6" md="8" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Dirección"
                                                        v-model="cliente.direccion"
                                                        required
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="6" md="4" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="colonias"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Colonia"
                                                        persistent-hint
                                                        v-model="cliente.colonia_id"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <h2 class="preguntaNomina">¿El cliente cuenta con el sistema de nómina?</h2>
                                                <v-switch
                                                    class="pa-0 ma-0"
                                                    v-model="cliente.nominas"
                                                    :label="cliente.nominas ? 'Nómina activa':'Nómina inactiva'"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <h2 class="preguntaNomina">¿El cliente cuenta con timbres ilimitados?</h2>
                                                <v-switch
                                                    class="pa-0 ma-0"
                                                    v-model="cliente.timbrar_libre"
                                                    :label="cliente.timbrar_libre ? 'Timbres ilimitados':'Timbres limitados'"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <h2 class="preguntaNomina">¿El cliente usá ODESSA?</h2>
                                                <v-switch
                                                    class="pa-0 ma-0"
                                                    v-model="cliente.odesa"
                                                    :label="cliente.odesa ? 'Usá ODESSA':'No usá ODESSA'"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                       @click="cerrarModal()" 
                                       :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Clientes';
import Notify from '@/plugins/notify';
import apiClientes from '@/api/clientes';
import catalogoApis from "../api/catalogos";


export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Teléfono',
                    name: 'telefono',
                    filterable: false
                },
                {
                    label: 'Sistema',
                    name: 'sistema',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            cliente           : {
                id              : "",
                nombre          : "",
                telefono        : "",
                correo          : "",
                direccion       : "",
                estado_id       : null,
                municipio_id    : null,
                cp_id           : null,
                colonia_id      : null,
                nominas         : true,
                timbrar_libre: false,
                odesa          : false,
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : "",
            estado_id       : null,
            municipio_id    : null,
            codigo_postal_id: null,
            empresas        : [],
            estados      : [],
            codigosP        : [],
            colonias        : [],
            rol             : "",
            estado_value : null,
            nombre          : null,
            correo          : null,
            telefono        : null,
            busca_cp        : "",
            municipio       : "",
            estado          : "",
            datosLogin      : null,
            isLoadingModal  : false,
        }
    },
    watch: {
        busca_cp: function(val){
            if(val != null){
                if(val.length == 5){
                    this.busca_codigos();
                }
                else{
                    this.estado     = "";
                    this.municipio  = "";
                    this.colonias   = [];
                }
            }
        }
    },
    methods: {
        /**
         * @method getPaginationInfo Este método se utiliza para la paginación de la tabla.
         * @description Este método se utiliza para la paginación de la tabla con la implementacion de GrapQL.
         * Se ejecuta como emit del componente tabla.
         * @param {Object} data objeto con datos para el número de la página y cuantos elementos en la página 
         */
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },

        /**
         * @method abrirModal Este método realiza la acción para abrir un modal.
         * @description Este método realiza la acción para abrir un modal dependiento de la acción que se le envie, puede ser 'add' o 'update'.
         * @param {string} accion Esta variable puete tomar el valor de 'add' o 'update'.
         * @param {Object} data Esta variable es un objeto, son los datos de un registro cliente.
        */
        async abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal =  true;

            if(accion == "add"){
                this.imagen = " /static/modal/clienteCreate.svg";
                this.tituloModal = "Registrar cliente";
                this.isLoadingModal = false;
                
            } else {
                this.imagen = " /static/modal/clienteUpdate.svg";
                this.tituloModal = "Actualizar cliente";
                    this.cliente.id         = data.id;
                    this.cliente.nombre     = data.nombre;
                    this.cliente.telefono   = data.telefono;
                    this.cliente.correo     = data.correo;
                    this.cliente.direccion  = data.direccion;
                    this.estado             = data.nombre_estado;
                    this.municipio          = data.nombre_municipio;
                    this.cliente.colonia_id = data.colonia_id;
                    this.cliente.nominas    = data.nominas;
                    this.cliente.timbrar_libre = data.timbrar_libre;
                    this.cliente.odesa     = data.odesa;
                    await this.getCP(data.cp_id);
                    this.isLoadingModal = false;
            }
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },

        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValues() para resetear los valores.
         */
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },

        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValues() {
            this.cliente      = {
                id              : "",
                nombre          : "",
                telefono        : "",
                correo          : "",
                direccion       : "",
                estado_id       : null,
                municipio_id    : null,
                cp_id           : null,
                colonia_id      : null,
                nominas         : true,
                timbrar_libre   : false,
                odesa           : false,
            };
            this.estado_id          = null;
            this.codigosP           = [];
            this.municipio_id       = null;
            this.codigo_postal_id   = null;
            this.colonia_id         = null;
            this.estado             = "";
            this.municipio          = "";
            this.busca_cp           = null;
            this.colonias           = [];

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
                this.estado_value = null;
                this.nombre = null;
                this.correo = null;
                this.telefono = null;
                
            });
        },

        /**
         * @method setFilters Este método se ejecuta despues de dar clic en el botón buscar
         * @description Este método se ejecuta despues de dar clic en el botón buscar, se envian los filtros que se indicaron en el modal búsqueda. Y se reflejan en la tabla principal.
         * Se ejecuta como emit del componente tabla.
         */
        setFilters() {
            this.filters = { AND:[]};
            
            let nombre = this.nombre;
            let correo = this.correo;
            let telefono = this.telefono;
            let estado = this.estado_value;
            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(correo != null && correo != undefined && correo != ""){
                this.filters.AND.push({column:'CORREO', operator:'ILIKE', value:"%"+correo+"%"})
            }

            if(telefono != null && telefono != undefined && telefono != ""){
                this.filters.AND.push({column:'TELEFONO', operator:'ILIKE', value:"%"+telefono+"%"})
            }  
                
            if(estado != null && estado != undefined && estado != ""){
                this.filters.AND.push({column:'ESTADO_ID', value:estado})
            }
            this.$apollo.queries.clientes.refetch();
            this.resetValues();
        },

        /**
         * @method eliminar Este método elimina el registro cliente
         * @description Este método elimina el registro cliente y actualiza la tabla en caso de que la petición sea correcta.
         * @param {Object} data Esta variable es un objeto, son los datos del cliente.
         */
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar a este cliente?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading =  true;
                    apiClientes.deleteCliente(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El cliente ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.clientes.refetch();
                    })
                    .catch(err => {
                        this.isLoading =  false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },

        /**
         * @method guardar Este método guarda los datos que se necesitan para el registro de un cliente.
         * @description Este método guarda los datos que se necesitan para un cliente.
         * El método se llama para crear o actulizar un registro para un cliente. En caso de que la petición
         * sea correcta la tabla se actualiza.
         */
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.isLoading =  true;
                if(this.accion === "add") {
                    apiClientes.addCliente(this.cliente)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se creó el nuevo cliente satisfactoriamente.");
                        this.$apollo.queries.clientes.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                        
                    });
                }
                else if(this.accion === "update") {
                    apiClientes.updateCliente(this.cliente)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se actualizó el cliente satisfactoriamente.");
                        this.$apollo.queries.clientes.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },

        /**
         * @method getCP Este método sirve para el codigo postal.
         * @description Este método sirve para el codigo postal. Se envia el id.
         * @param {int} id Id de código postal.
         */
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },

        /**
         * @method getEstados Este metodo realiza la petición a la api de estados.
         * @description Este metodo realiza la petición a la api de estados.
         */
        async getEstados(){
            let params= { activo:true, paginate:false, order: 'asc'};
            await catalogoApis.getEstados(params).then((response) => {
                this.estados = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los estados.");
            });
        },

        /**
         * @method busca_codigos Método que se ejecuta buscar el estado y la ciudad de acuedo al CP.
         * @description Método que se ejecuta buscar el estado y la ciudad de acuedo al CP.
         */
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis.getCP(params).then((response) => {
                let result = response.data;

                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado     = "";
                    this.municipio  = "";
                    this.colonias   = [];
                    return;
                }
                this.estado                 = result[0].nomb_estado;
                this.municipio              = result[0].nomb_mun;
                this.cliente.estado_id      = result[0].estado_id;
                this.cliente.municipio_id   = result[0].municipio_id;
                this.cliente.cp_id          = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate:false }).then((response) => {
                    self.colonias = response.data;
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y municipios."
                );
            });
        },

        /**
         * @method datosIniciales Método que se ejecuta en el ciclo de vida de la vista create()
         */
        datosIniciales() {
            this.datosLogin = this.$session.get("usuario");
            this.rol        = this.datosLogin.rol.name;
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }

    },

    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created() {
        this.datosIniciales();
    },

    /**
     * @description mounted(). Se inician los valores que se requieren al montar los componentes en la vista.
    */
    mounted(){
        this.getEstados();
        this.$apollo.queries.clientes.skip = false;
    },

    /**
     * @name apollo:clientes
     * @description GrapQL apollo, query clientes, realiza la petición a clientes y los datos se utilizan en el componete tabla. 
     */
    apollo: {
        clientes: {
            query       : queries.clientesTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.clientes;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .preguntaNomina{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #828282 !important;
        letter-spacing: -0.2px;
        padding-bottom: 20px;
    }
</style>
