import gql from 'graphql-tag';

const clientesTableQuery = gql`
    query clientes($whereCondition:ClientesWhereWhereConditions ,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        clientes(where:$whereCondition,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id
                nombre,
                correo,
                telefono,
                direccion,
                estado_id,
                municipio_id,
                cp_id,
                colonia_id,
                nominas,
                timbrar_libre,
                odesa
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { clientesTableQuery };

export default queries;
